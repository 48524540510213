import React, { useState } from "react";
import Slider from "react-slick";
import { htmlSafe, stripParagraphTags } from "modules/utils";
import clsx from "clsx";

import SvgDivider from "modules/theme/ims/hero-carousel-divider.svg";
import CtaButton from "components/Cta/Button";
import { CtaProps } from "components/Cta/types";
import { ButtonStyle } from "components/Button/constants";
import ArrowSVG from "modules/theme/icons/chevrons/wide-chevron-down.svg";
import PreloadImage from "modules/lazyload/PreloadImage";

import "slick-carousel/slick/slick.css";

export type CarouselSliderProps = {
  arrow?: boolean;
  hero_carousel_module: {
    hero_carousel_items: {
      hero_carousel_image: {
        url: string;
      };
      cta: CtaProps;
      hero_carousel_subtext: string;
      hero_carousel_title: string;
      image_overlay: boolean;
      slogan_title?: boolean;
    };
  }[];
  right_align_button?: boolean;
  slider_speed: number;
};

const CarouselSlider: React.FunctionComponent<CarouselSliderProps> = ({
  arrow,
  hero_carousel_module,
  slider_speed,
}) => {
  const heroCarousel = hero_carousel_module;
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    fade: true,
    swipeToSlide: false,
    pauseOnHover: true,
    arrows: false,
    autoplaySpeed: slider_speed ? slider_speed : 3000,
    beforeChange: (oldIndex: any, newIndex: any) => {
      setCurrentSlide(newIndex);
    },
    // afterChange: (index: any) => setCurrentSlide(index),
    appendDots: (dots: any) => (
      <div>
        <ul className="container dots-flex">
          {dots.map((item: any, index: any) => {
            return <li key={index}>{item.props.children}</li>;
          })}
        </ul>
      </div>
    ),
    customPaging: (index: any) => {
      const dotClassName = clsx(index === currentSlide && "active", "dot");
      return <button className={dotClassName}>{index + 1}</button>;
    },
  };

  return (
    <Slider {...settings}>
      {heroCarousel.map((item: any, index) => {
        const heroImage = item.hero_carousel_items.hero_carousel_image?.url;
        const title = item.hero_carousel_items.hero_carousel_title;
        const safeTitle = htmlSafe(stripParagraphTags(title));
        const text = item.hero_carousel_items.hero_carousel_subtext;
        const slogan = item.hero_carousel_items.slogan_title;
        const sloganText = (
          <>
            <span>Powering</span>
            <span>Best</span>
            <span>Practice</span>
          </>
        );
        const className = clsx(
          "carousel",
          item.hero_carousel_items.image_overlay && "hasOverlay",
          slogan && "hasSlogan"
        );
        const displayTitle = index === 0 ? <h1>{safeTitle}</h1> : <h2>{safeTitle}</h2>;
        const displaySlogan = index === 0 ? <h1>{sloganText}</h1> : <h2>{sloganText}</h2>;

        return (
          <div key={index}>
            <div className={className}>
              <PreloadImage
                className="image"
                alt={htmlSafe(stripParagraphTags(item.hero_carousel_items.hero_carousel_title))}
                desktop={{ height: 800, width: 1920, aspectRatio: "12:5" }}
                mobile={{ height: 400, width: 360, aspectRatio: "9:10" }}
                src={heroImage}
              />
              <div className="container small">
                <SvgDivider className="heroCarouselDivider" />
                <div className="carouselContentWrapper">
                  {slogan ? (
                    displaySlogan
                  ) : (
                    <>
                      {title && displayTitle}
                      {text && <p>{text}</p>}
                    </>
                  )}
                  {arrow && <ArrowSVG />}
                  {(item.hero_carousel_items.cta.page_link.length > 0 || item.hero_carousel_items.cta.custom_url) && (
                    <div className="carouselLink">
                      <CtaButton
                        {...(item.hero_carousel_items.cta as CtaProps)}
                        defaultColour={ButtonStyle.BRAND_BERRY}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default React.memo(CarouselSlider);
